"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserService = void 0;
var json_helpers_1 = require("../common/helpers/json-helpers");
var UserService = /** @class */ (function () {
    function UserService(config) {
        this.cname = UserService.cname;
        this.isInitialized = false;
        this.config = config;
    }
    Object.defineProperty(UserService.prototype, "currentUserInfo", {
        get: function () {
            return this.__currentUserInfo;
        },
        enumerable: false,
        configurable: true
    });
    UserService.getInstance = function (config) {
        if (!UserService.instance) {
            UserService.instance = new UserService(config);
        }
        return UserService.instance;
    };
    /**
     * Update the config and initialize
     * @param config - embedded or remote config
     */
    UserService.prototype.init = function (config) {
        this.config = config;
        return (this.isInitialized = true);
    };
    UserService.prototype.signIn = function (username, password) {
        var _this = this;
        var self = this;
        var signInPromise = new Promise(function (fulfill, reject) {
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    switch (xhr.status) {
                        case 200:
                            console.info("%cSign in was a success.", "background: rgba(24, 255, 0, 1); color: black;");
                            // Appears that we no longer need to extract the Authorization header
                            // because the LoginInfo responseText has the authorization attached.
                            //const authorization = xhr.getResponseHeader("Authorization");
                            var loginInfo = (0, json_helpers_1.deserialize)(xhr.responseText);
                            self.handleSignInResults(loginInfo, fulfill);
                            break;
                        case 403:
                            var res = void 0;
                            try {
                                res = JSON.parse(xhr.response);
                            }
                            catch (err) {
                                // Meh, what we gonna do? Retain original behavior if Virgil gives no parseable response.
                            }
                            if (res) {
                                reject(res);
                            }
                            else {
                                reject("Incorrect credentials: " + xhr.status);
                            }
                            break;
                        default:
                            //console.log('%s: signIn(..): No handler for xhr.status = ' + xhr.status);
                            reject("No handler for xhr.status = " + xhr.status);
                    }
                }
            };
            xhr.open("POST", "".concat(_this.config.api + "auth/login"));
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.send(JSON.stringify({
                userName: username,
                password: password,
            }));
        });
        return signInPromise;
    };
    /**
     * Update the loginInfo object, save it, and execute a Promise's fulfill()
     * Note that the fulfill() function is not created in this handler.
     */
    UserService.prototype.handleSignInResults = function (loginInfo, fulfill) {
        this.__currentUserInfo = loginInfo.userInfo;
        this.__currentUserInfo.authorization = loginInfo.authorization;
        this.__currentUserInfo.authSessionId =
            loginInfo.authorization && loginInfo.authorization.length > 0
                ? loginInfo.authorization.match(/Credential=([0-9a-f]*)/)[1]
                : null;
        this.__currentUserInfo.productYears = loginInfo.productYears;
        if (typeof this.__currentUserInfo.productYears !== "undefined") {
            this.__currentUserInfo.hasCTN2 = this.__currentUserInfo.productYears.some(function (year) { return year <= 2016; });
            this.__currentUserInfo.hasCTN3 = this.__currentUserInfo.productYears.some(function (year) { return year >= 2017; });
        }
        else {
            this.__currentUserInfo.hasCTN2 = false;
            this.__currentUserInfo.hasCTN3 = true;
        }
        this.__currentUserInfo.institution = loginInfo.institution;
        this.__currentUserInfo.redirectUrl = loginInfo.redirectUrl;
        fulfill(this.__currentUserInfo);
    };
    UserService.prototype.signInWIP = function (username, password) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var requestOptions = {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                redirect: "follow",
                referrer: "no-referrer",
                body: JSON.stringify({
                    userName: username,
                    password: password,
                }),
            };
            fetch("".concat(_this.config.api + "auth/login"), requestOptions)
                .then(function (response) {
                response.json().then(function (loginInfo) {
                    console.log(loginInfo);
                    resolve(loginInfo);
                });
            })
                .catch(function (err) {
                reject(err);
            });
        });
    };
    UserService.prototype.getUserSelf = function (virgilToken) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var requestOptions = {
                method: "GET",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    Authorization: "VIRGIL Credential=" + virgilToken,
                },
                redirect: "follow",
                referrer: "no-referrer",
            };
            fetch("".concat(_this.config.api + "users/self"), requestOptions)
                .then(function (response) {
                response.json().then(function (userInfo) {
                    console.log(userInfo);
                    resolve(userInfo);
                });
            })
                .catch(function (err) {
                reject(err);
            });
        });
    };
    UserService.cname = "UserService";
    UserService.logStyle = "background-color: #1e1e1e; color: #cbdeff;";
    return UserService;
}());
exports.UserService = UserService;
