"use strict";
/**
 * build-info provides a set of constants that are set to "baked-in" or compiler replaced values.
 * Classes and other modules will import build-info when it's these values are useful for
 * reporting, debugging, or sometimes cache and storage busting.
 * @packageDocumentation
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildTimeBuster = exports.buildTime = exports.version = exports.appAuthor = exports.appName = void 0;
/**
 * The name of the package or app
 */
exports.appName = (function () {
    var result = "calvert-login-app";
    return result == "${" + "appName" + "}" ? "app-name-not-baked-in" : result;
})();
/**
 * The name of the author
 */
exports.appAuthor = (function () {
    var result = "Michael Prescott <michael.prescott@gmail.com>";
    return result == "${" + "appAuthor" + "}" ? "Michael Prescott" : result;
})();
/**
 * Version of this build, extracted from package.json
 * Set by webpack.config.js
 */
exports.version = (function () {
    var result = "1.0.0";
    return result == "${" + "version" + "}" ? "0.0.0" : result;
})();
/**
 * The date and time that this release was built. This is meant to be humanly readable.
 * @see buildTimeBuster for a cache busting or an acceptable url param value
 * Set by webpack.config.js
 */
exports.buildTime = (function () {
    var result = "April 22 2022, 1:00:40 pm";
    return result == "${" + "buildTime" + "}" ? "January 1, 0000, 00:00:00 am" : result;
})();
/**
 * The date and time that this release was built. Compact format that is useful
 * for cache and storage busting.
 * Set by webpack.config.js
 */
exports.buildTimeBuster = (function () {
    var result = "20220422_130040";
    return result == "${" + "buildTimeBuster" + "}" ? "00000000_000000" : result;
})();
