"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extend = void 0;
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
function extend() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    var i = 0;
    var result = {};
    for (; i < args.length; i++) {
        var attributes = args[i];
        for (var key in attributes) {
            result[key] = attributes[key];
        }
    }
    return result;
}
exports.extend = extend;
