(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("Edmentum", [], factory);
	else if(typeof exports === 'object')
		exports["Edmentum"] = factory();
	else
		root["Edmentum"] = root["Edmentum"] || {}, root["Edmentum"]["LoginModule"] = factory();
})(self, function() {
return 