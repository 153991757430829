"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeHtmlStringWhitespace = exports.countWords = exports.kebabToCamelCase = exports.camelToKebabCase = exports.uppercaseFirstLetter = exports.lowercaseFirstLetter = void 0;
function lowercaseFirstLetter(str) {
    return str.charAt(0).toLowerCase() + str.slice(1);
}
exports.lowercaseFirstLetter = lowercaseFirstLetter;
function uppercaseFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
exports.uppercaseFirstLetter = uppercaseFirstLetter;
/**
 * Convert from camelCase to kebab-case
 * @param {*} str String to convert
 */
function camelToKebabCase(str) {
    return str
        .replace(/([a-z0-9])([A-Z])/g, "$1-$2")
        .replace(/([A-Z])([A-Z])(?=[a-z])/g, "$1-$2")
        .toLowerCase();
}
exports.camelToKebabCase = camelToKebabCase;
/**
 * Convert from kebab-case to camelCase
 * @param {*} str String to convert
 */
function kebabToCamelCase(str) {
    return str.replace(/(-\w)/g, function (m) {
        return m[1].toUpperCase();
    });
}
exports.kebabToCamelCase = kebabToCamelCase;
/**
 * Count words in a block of text
 * REFERENCE: https://www.mediacollege.com/internet/javascript/text/count-words.html
 * @param s string to parse
 */
function countWords(s) {
    s = s.replace(/(^\s*)|(\s*$)/gi, ""); //exclude  start and end white-space
    s = s.replace(/[ ]{2,}/gi, " "); //2 or more space to 1
    s = s.replace(/\n /, "\n"); // exclude newline with a start spacing
    return s.split(" ").filter(function (str) {
        return str != "";
    }).length;
}
exports.countWords = countWords;
function removeHtmlStringWhitespace(htmlStr) {
    // remove newline / carriage return
    htmlStr = htmlStr.replace(/\n/g, "");
    // remove whitespace (space and tabs) before tags
    htmlStr = htmlStr.replace(/[\t ]+\</g, "<");
    // remove whitespace between tags
    htmlStr = htmlStr.replace(/\>[\t ]+\</g, "><");
    // remove whitespace after tags
    htmlStr = htmlStr.replace(/\>[\t ]+$/g, ">");
    // remove newline / carriage between properties
    htmlStr = htmlStr.replace(/[\n\t ]+/g, " ");
    return htmlStr;
}
exports.removeHtmlStringWhitespace = removeHtmlStringWhitespace;
