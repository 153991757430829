"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CookieService = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
var object_helpers_1 = require("../common/helpers/object-helpers");
var CookieService = /** @class */ (function () {
    function CookieService() {
        this.cname = CookieService.cname;
        this.isInitialized = false;
        //
    }
    CookieService.getInstance = function () {
        if (!CookieService.instance) {
            CookieService.instance = new CookieService();
        }
        return CookieService.instance;
    };
    /**
     * Update the config and initialize
     * @param config - embedded or remote config
     */
    CookieService.prototype.init = function () {
        return (this.isInitialized = true);
    };
    CookieService.prototype.setItem = function (key, value, attributes) {
        var result;
        if (typeof document === "undefined") {
            return;
        }
        if (arguments.length > 1) {
            attributes = (0, object_helpers_1.extend)({
                path: "/",
            }, this.defaults, attributes);
            if (typeof attributes.expires === "number") {
                var expires = new Date();
                expires.setMilliseconds(expires.getMilliseconds() + attributes.expires * 864e5);
                attributes.expires = expires;
            }
            // We're using "expires" because "max-age" is not supported by IE
            attributes.expires = attributes.expires ? attributes.expires.toUTCString() : "";
            try {
                result = JSON.stringify(value);
                if (/^[\{\[]/.test(result)) {
                    value = result;
                }
            }
            catch (e) { }
            value = encodeURIComponent(String(value)).replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g, decodeURIComponent);
            key = encodeURIComponent(String(key));
            key = key.replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent);
            key = key.replace(/[\(\)]/g, escape);
            var stringifiedAttributes = "";
            for (var attributeName in attributes) {
                if (!attributes[attributeName]) {
                    continue;
                }
                stringifiedAttributes += "; " + attributeName;
                if (attributes[attributeName] === true) {
                    continue;
                }
                stringifiedAttributes += "=" + attributes[attributeName];
            }
            return (document.cookie = key + "=" + value + stringifiedAttributes);
        }
    };
    CookieService.prototype.getItem = function (key) {
        var result;
        if (typeof document === "undefined") {
            return;
        }
        if (!key) {
            result = {};
        }
        // To prevent the for loop in the first place assign an empty array
        // in case there are no cookies at all. Also prevents odd result when
        // calling "get()"
        var cookies = document.cookie ? document.cookie.split("; ") : [];
        var rdecode = /(%[0-9A-Z]{2})+/g;
        var i = 0;
        for (; i < cookies.length; i++) {
            var parts = cookies[i].split("=");
            var cookie = parts.slice(1).join("=");
            if (cookie.charAt(0) === '"') {
                cookie = cookie.slice(1, -1);
            }
            try {
                var name_1 = parts[0].replace(rdecode, decodeURIComponent);
                cookie = cookie.replace(rdecode, decodeURIComponent);
                if (key === name_1) {
                    result = cookie;
                    break;
                }
                if (!key) {
                    result[name_1] = cookie;
                }
            }
            catch (e) { }
        }
        return result;
    };
    CookieService.prototype.removeItem = function (key, attributes) {
        this.setItem(key, "", (0, object_helpers_1.extend)(attributes, {
            expires: -1,
        }));
    };
    CookieService.cname = "CookieService";
    CookieService.logStyle = "background-color: #1e1e1e; color: #cb00ff;";
    return CookieService;
}());
exports.CookieService = CookieService;
