"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadFile = void 0;
var EXT_CONTENT_TYPE;
(function (EXT_CONTENT_TYPE) {
    EXT_CONTENT_TYPE["json"] = "application/json";
    EXT_CONTENT_TYPE["mp3"] = "audio/mpeg";
    EXT_CONTENT_TYPE["txt"] = "text/plain";
})(EXT_CONTENT_TYPE || (EXT_CONTENT_TYPE = {}));
function loadFile(url) {
    return __awaiter(this, void 0, void 0, function () {
        var ext, contentType;
        var _this = this;
        return __generator(this, function (_a) {
            ext = url.substr(url.lastIndexOf(".") + 1);
            contentType = EXT_CONTENT_TYPE[ext];
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    var requestOptions = {
                        method: "GET",
                        mode: "cors",
                        cache: "no-cache",
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": contentType,
                        },
                        redirect: "follow",
                        referrer: "no-referrer",
                    };
                    fetch("".concat(url), requestOptions)
                        .then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                        var res, _a, responseClone;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    if (!response.ok)
                                        reject(new Error("".concat(response.status, " ").concat(response.statusText)));
                                    _a = ext;
                                    switch (_a) {
                                        case "json": return [3 /*break*/, 1];
                                        case "mp3": return [3 /*break*/, 3];
                                        case "txt": return [3 /*break*/, 5];
                                    }
                                    return [3 /*break*/, 7];
                                case 1:
                                    responseClone = response.clone();
                                    return [4 /*yield*/, responseClone.json()];
                                case 2:
                                    res = _b.sent();
                                    return [3 /*break*/, 8];
                                case 3: return [4 /*yield*/, response.arrayBuffer()];
                                case 4:
                                    res = _b.sent();
                                    return [3 /*break*/, 8];
                                case 5: return [4 /*yield*/, response.text()];
                                case 6:
                                    res = _b.sent();
                                    return [3 /*break*/, 8];
                                case 7:
                                    console.error("Unknown file extension: %o", ext);
                                    _b.label = 8;
                                case 8:
                                    resolve(res);
                                    return [2 /*return*/];
                            }
                        });
                    }); })
                        .catch(function (err) {
                        reject(err);
                    });
                })];
        });
    });
}
exports.loadFile = loadFile;
// REFERENCE:
// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
// The Promise returned from fetch() won’t reject on HTTP error status even if
// the response is an HTTP 404 or 500. Instead, it will resolve normally (with
// ok status set to false), and it will only reject on network failure or if
// anything prevented the request from completing.
