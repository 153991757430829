"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deserialize = exports.serialize = void 0;
/**
 * @param {function} normalizeState - Unique process that must be run against restored properties
 * @param {boolean} useForce - restore state despite global configurations
 */
function serialize(value) {
    var result = null;
    try {
        result = JSON.stringify(value);
    }
    catch (e) {
        console.error("Couldn't convert value into a JSON string: ", value);
    }
    return result;
}
exports.serialize = serialize;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function deserialize(value) {
    var result = null;
    try {
        result = JSON.parse(value);
    }
    catch (e) {
        console.error("Couldn't parse JSON string into a JavaScript object: ", value);
    }
    return result;
}
exports.deserialize = deserialize;
